import { GlobalSelectedEnv, globalStorageScan } from './../../../shared/shared.data';
import { Component, Inject, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { RunService } from '../../../services/run.service';
import { SnackbarService } from "../../../services/snackbar.service";
import { VERSION } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { RegionsService } from '../../../services/regions.service';
import { Handler } from '../handler/handler';
import { Categories } from '../../../models/project-autocode-config.model';
import { TestSuiteService } from '../../../services/test-suite.service';
import { SuggestionCounts } from '../../../models/project.model';
import { timer } from "rxjs/index";
import { JobsService } from "../../../services/jobs.service";
import { ResourceService } from "../../../services/resource.service";
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs/Observable";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ManageRolesComponent } from "../manage-roles/manage-roles.component";
import { SelectEndpointsDialogComponent } from "../select-endpoints-dialog/select-endpoints-dialog.component";
import { Jobs } from '../../../models/jobs.model';
import { MixpanelService } from '../../../services/mixpanel.service';

@Component({
    selector: 'app-adv-run',
    templateUrl: './adv-run.component.html',
    styleUrls: ['./adv-run.component.scss'],
    providers: [TestSuiteService, RegionsService, RunService, SnackbarService, JobsService, ResourceService]
})
export class AdvRunComponent implements OnInit {
    @Output() emitData = new EventEmitter<string[]>();
    list = [];
    showSpinner: boolean = false;
    length = 0;
    page = 0;
    pageSize = 100;
    regions;
    tags;
    suites;
    newRegion;
    category: string[];
    selectedCategories: string[] = [];
    emailReport: boolean = false;
    categoryCount = [];
    categoryCountType = [];
    cloudShow: boolean = true;
    newRegion2: any;
    newRegionCheck: boolean = false;
    runs;
    runId;
    globScan = globalStorageScan;
    advance: boolean = false;
    envId;
    // envName;
    // envUrl;
    jobs:any;
    selectedProfile: Jobs = new Jobs();
    selectedProfileId;
    jobsNoIssue;
    resources;
    selectedResources = [];
    firstFormGroup: FormGroup;
    scannerFormGroup: FormGroup;
    archiveAllFlag: Boolean = false;
    archiveIndeterminateFlag: Boolean = false;

    selectionChangeArchive() { }
    selectArchiveAll(obj) { }

    constructor(private formBuilder: FormBuilder, private regionService: RegionsService, private runService: RunService, private router: Router, private handler: Handler, private testSuiteService: TestSuiteService, private snackbarService: SnackbarService,
        @Inject(MAT_DIALOG_DATA) public data: any, private jobsService: JobsService, private resource: ResourceService,
        public dialogRef: MatDialogRef<AdvRunComponent>, private dialog: MatDialog, private _cdr: ChangeDetectorRef,
        private mixpanelService: MixpanelService
    ) {
    }

    ngOnInit() {

        // console.log("endpoints", this.data[2])
        if (this.data.length == 3) {
            this.cloudShow = false;

            for (let e of this.data[2]) {

                if (this.selectedEndpoints == "")
                    this.selectedEndpoints = e.method + ":" + e.endpoint
                else
                    this.selectedEndpoints = this.selectedEndpoints + ", " + e.method + ":" + e.endpoint
            }

        }
        this.firstFormGroup = this.formBuilder.group({
            selectedProfileForm: ['', Validators.required],

        });

        this.scannerFormGroup = this.formBuilder.group({
            selectedScanner: ['', Validators.required]

        });

        this.getJobs();
        this.getResources();
        this.newRegion = this.data[0].regions;
        if (this.globScan.Scan_Global) {
            this.newRegion = this.globScan.Scan_Global;
        }

        // if (this.data[1] != null) {
        // this.envId = this.data[1].id;
        // this.envName = this.data[1].name;
        // this.envUrl = this.data[1].baseUrl;
        // }
        // else {
        // this.envId = this.data[0].environment.id;
        // this.envName = this.data[0].environment.name;
        // this.envUrl = this.data[0].environment.baseUrl;
        // }
        this.get();
        this.tags = ''; // this.data[0].tags.join(',');
        this.suites = '';
        setTimeout(() => {
        this.getCounts();},700)
        // if (this.data[0].categories) {
        //     this.selectedCategories = this.data[0].categories.split(',')
        //         // tslint:disable-next-line: only-arrow-functions
        //         .map(function (item) {
        //             return item.trim();
        //         });
        //     this.selectionChangeCreate();
        // }
    }

    getSelectedScan(event) {
        if (event != '') {
            this.newRegion = event;
            this.globScan.Scan_Global = this.newRegion;
        }
        this.list.map(reg => {
            if (reg.name == this.newRegion && reg.status == 'INACTIVE') {
                this.scannerFormGroup.controls.selectedScanner.markAllAsTouched();
                this.scannerFormGroup.controls.selectedScanner.setErrors({ 'incorrect': true });
            }
        })
    }

    ngAfterViewInit() {

    }

    validateBaseUrl() {
        // console.log(" base url", this.thirdFormGroup.controls['urlCtrl'].value)
        // console.log(this.env.baseUrl)
        if (this.selectedProfile.environment.baseUrl == '127.0.0.1' || this.selectedProfile.environment.baseUrl.split("//")[1].split(":")[0] == '127.0.0.1') {
            this.firstFormGroup.controls.selectedProfileForm.markAllAsTouched();
            this.firstFormGroup.controls.selectedProfileForm.setErrors({ 'incorrect': true });

        }
        if (this.selectedProfile.environment.baseUrl.split("//")[1].split(".").length < 2 && this.selectedProfile.environment.baseUrl.split("//")[1].split(":").length < 2) {
            this.firstFormGroup.controls.selectedProfileForm.markAllAsTouched();
            this.firstFormGroup.controls.selectedProfileForm.setErrors({ 'incorrect': true });

        }
        if (this.selectedProfile.environment.baseUrl.split("//")[1].split(".").length < 2) {
            this.firstFormGroup.controls.selectedProfileForm.markAllAsTouched();
            this.firstFormGroup.controls.selectedProfileForm.setErrors({ 'incorrect': true });

        }

        if (this.selectedProfile.environment.baseUrl.indexOf('<host>') >= 0 || this.selectedProfile.environment.baseUrl.indexOf('<port>') >= 0 || this.selectedProfile.environment.baseUrl.split('//')[1].split('.')[1]?.length < 1) {
            this.firstFormGroup.controls.selectedProfileForm.markAllAsTouched();
            this.firstFormGroup.controls.selectedProfileForm.setErrors({ 'incorrect': true });


        }
    }

    getSelectedCategories() {
        this.validateBaseUrl();
        // if (this.selectedProfile.environment.baseUrl.indexOf('<host>') >= 0 || this.selectedProfile.environment.baseUrl.indexOf('<port>') >= 0 || this.selectedProfile.environment.baseUrl == 'http://127.0.0.1'
        //     || (this.selectedProfile.environment.baseUrl.split("//")[1].split(".").length < 2 && this.selectedProfile.environment.baseUrl.split("//")[1].split(":").length < 2)) {
        //     this.firstFormGroup.controls.selectedProfileForm.markAllAsTouched();
        //     this.firstFormGroup.controls.selectedProfileForm.setErrors({ 'incorrect': true });

        // }
        // else
        //     this.firstFormGroup.controls.selectedProfileForm.setErrors(null);

        this.selectedProfileId = this.selectedProfile.id;
        this.newRegion = this.selectedProfile.regions;
        if (this.selectedProfile.categories)
            this.selectedCategories = this.selectedProfile.categories.split(',');
        else
            this.selectedCategories = [];

        this.getAuthStatus();
        this.selectionChangeCreate();
    }

    get() {
        this.handler.activateLoader();
        this.regionService.getEntitled(this.page, this.pageSize).subscribe((results) => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.list = results['data'];
            // if Default Scanner name is null add list[0]lacotion
            if (this.newRegion == null) {
                this.newRegion = this.list[0].name;
            }
            if (this.newRegion != null) {
                this.newRegion2 = this.newRegion.split('/');
                this.newRegion = this.newRegion2[0];
            }
            if (this.newRegion2[1] != null) {
                this.newRegion = this.newRegion2[1];
            }
            // Default Scanner name is check exist or not if does not exist add list[0]lacation name
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].name === this.newRegion) {
                    this.newRegionCheck = true;
                }

            }
            if (this.newRegionCheck === false) {
                this.newRegion = this.list[0].name;
            }
            this.length = results['totalElements'];
            this.getSelectedScan('');
        }, (error) => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }

    run() {
        var endpointsObj = [];
        if (this.selectedResources.length > 0) {
            for (let r of this.selectedResources) {
                this.resources.map(a => {
                    if (a[4] == r)
                        endpointsObj.push(a)
                })
            }
        }
        this.envId = this.selectedProfile.environment.id;

        //mixpanel code starts

        var obj = {
            "categories": this.selectedCategories,
            "environment": this.selectedProfile.environment.name,
            "scanner": this.newRegion,
            "endpoints": this.selectedEndpoints
        }
        this.mixpanelService.trackMixpanel("Scan", obj);
        //mixpanel code ends


        this.dialogRef.close();
        this.runService.advRun(this.selectedProfile.id, this.newRegion, this.tags, this.suites, this.selectedCategories, this.emailReport, this.data[0].project.id, this.envId, endpointsObj, this.selectedEndpoints).subscribe((results) => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.emitData.next([results['data']]);
            this.snackbarService.openBackgroundMsgBar("Scan Triggered...");

        }, (error) => {
            this.handler.error(error);
        });
    }

    getCounts() {
        this.handler.activateLoader();
        this.testSuiteService.getCategoryCountsWithTags(this.data[0].project.id).subscribe((results) => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.categoryCount = results['data'];
            var categoryCount = results['data'];

            if (this.categoryCount && this.categoryCount.length > 0) {

                this.categoryCount = categoryCount.filter(object => {
                    return object.count !== 0;
                });

                this.categoryCount.map(a => {
                    if (a.rank == null)
                        a.rank = "25"
                })
                this.categoryCount.sort((a, b) => (parseInt(a.rank) > parseInt(b.rank)) ? 1 : ((parseInt(b.rank) > parseInt(a.rank)) ? -1 : 0))
            }

            if (this.selectedProfile?.categories) {
                this.selectedCategories = this.selectedProfile.categories.split(',')
                    // tslint:disable-next-line: only-arrow-functions
                    .map(function (item) {
                        return item.trim();
                    });
                this.selectionChangeCreate();
            } else{
                this.selectedCategories = this.data[0].categories.split(',')
                // tslint:disable-next-line: only-arrow-functions
                .map(function (item) {
                    return item.trim();
                });
            this.selectionChangeCreate();
            }
        });
    }

    toggleCloud() {
        this.cloudShow = !this.cloudShow;
    }

    compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const bandA = parseInt(a[3]);
        const bandB = parseInt(b[3]);

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    }

    replaceTags(obj1) {
        for (var i = 0; i < obj1.length; i++) {
            var obj = obj1[i][2];
            let res = "";

            if (obj != null) {

                res = obj.replace(/1#API/gi, 'OWASP API');
                obj1[i][3] = res.replace(/1#/gi, '').replace(/\D/g, '');
                res = res.replace(/1#- OTG-/gi, 'OWASP- OTG-');
                res = res.replace(/#- OTG-/gi, '- OTG-');
                //     res="OWASP #"+res;
                res = res.replace(/1#A/gi, 'OWASP #');

                //  res= res.replace(/#1/gi, '');
                res = res.replace(/OWASP-API/gi, 'OWASP #');
                //  result = res.split("/");
            }
            obj1[i][2] = res;

        }
        obj1.sort(this.compare);
        return obj1;
    }



    globalSelectedEnv = GlobalSelectedEnv;
    getJobs() {
        this.jobsService.getJobs(this.data[0].project.id, this.page, this.pageSize).subscribe(
            results => {
                // this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.jobs = results["data"];

                // if (this.globalSelectedEnv.envId != "") {
                //     this.jobs.map(j => {
                //         if (j.environment.id == this.globalSelectedEnv.envId) {
                //             this.selectedProfile = j;
                //             return;
                //         }
                //     })
                // }

                if (this.globalSelectedEnv.envId != "") {
                    for (var i = 0; i < this.jobs.length; i++) {
                        // if (this.jobs[i].name == 'Master' && this.jobs[i].environment.id == this.globalSelectedEnv.envId) {
                        //     this.selectedProfile = this.jobs[i];
                        //     i = this.jobs.length;
                        // }
                        // else
                         if (this.jobs[i].name == this.data[1].name) {
                            this.selectedProfile = this.jobs[i];
                            return;

                        }
                         else if (this.jobs[i].name == 'Master') {
                            this.selectedProfile = this.jobs[i];
                            i = this.jobs.length;
                            return;
                        }
                        
                    }
                    
                }

                // if (!this.selectedProfile.name) {
                //     for (let j of this.jobs) {
                //         if (j.name == 'Master') {
                //             this.selectedProfileId = j.id;
                //             this.selectedProfile = j;
                //         }
                //         else{
                //             this.selectedProfileId = j.id;
                //             this.selectedProfile = j;
                //         }
                //     }
                // }

                setTimeout(() => {

                    this.validateBaseUrl();
                    // if (this.selectedProfile.environment.baseUrl.indexOf('<host>') >= 0 || this.selectedProfile.environment.baseUrl.indexOf('<port>') >= 0
                    //     || (this.selectedProfile.environment.baseUrl.split("//")[1].split(".").length < 2 && this.selectedProfile.environment.baseUrl.split("//")[1].split(":").length < 2)) {
                    //     this.firstFormGroup.controls.selectedProfileForm.markAllAsTouched();
                    //     this.firstFormGroup.controls.selectedProfileForm.setErrors({ 'incorrect': true });
                    // }
                }, 500);

                this.getAuthStatus();
                this.getNoIssueJob();
            },

            error => {
                this.handler.hideLoader();
                this.handler.error(error);
            }
        );

    }

    getNoIssueJob() {
        this.handler.activateLoader();
        this.jobsService.getJobsNoIssues(this.data[0].project.id, this.page, this.pageSize).subscribe(
            results => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.jobsNoIssue = results["data"];
                this.jobs = this.jobs.concat(this.jobsNoIssue);

            },
            error => {
                this.handler.hideLoader();
                this.handler.error(error);
            }
        );
    }

    uniqueResources = [];
    uniqueResources2 = [];
    getResources() {
        this.resources = [];
        this.uniqueResources = [];
        this.uniqueResources2 = [];
        this.resource.getEndpoint(this.data[0].project.id).subscribe((results) => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }

            this.resources = results['data']
            this.resources.map(a => {
                this.uniqueResources.push(a[4]);
            })

            this.resources.map(a => {
                var obj = {
                    selected: false,
                    method: a[1],
                    endpoint: a[0],
                    resource: a[4]
                }
                this.endpointList.push(obj);
            })

            this.uniqueResources2 = this.uniqueResources.filter((value, index) => this.uniqueResources.indexOf(value) == index)

            // this.createEndpointFilters();

        }, (error) => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }

    endpointList = [];
    filteredOptionsEndpoint: Observable<string[]>;
    selectedEndpoints = "";
    selectedEndpoints2 = [];
    openEndpointsDialog() {
        const dialogRef = this.dialog.open(SelectEndpointsDialogComponent, {
            width: "1000px",
            data: [
                this.endpointList,
                this.selectedEndpoints
            ]
        });

        dialogRef.componentInstance.emitData.subscribe(data1 => {
            this.selectedEndpoints = data1;
        });
    }

    cat = new FormControl();
    allComplete: boolean = false;
    intermediateFlag: boolean = false;
    setAll(e) {
        if (e) {
            this.selectedCategories = this.categoryCount.map(item => {
                return item.category;
            });
            this.allComplete = true;
        }
        else {
            this.allComplete = false;
            this.selectedCategories = [];
        }

    }

    selectionChangeCreate() {
        if (this.selectedCategories.length == this.categoryCount.length) {
            this.allComplete = true;
            this.intermediateFlag = false;
        }

        else if (this.selectedCategories.length != 0 && (this.selectedCategories.length < this.categoryCount.length)) {
            this.intermediateFlag = true;
            this.allComplete = false;
        }
        else {
            this.allComplete = false;
            this.intermediateFlag = false;
        }
    }

    getAuthStatus() {
        // console.log(this.selectedProfile.environment.auths)
        var rbacAuth = true;
        var rbacAuthAvailable = false;

        var abacAuth = true;
        var abacAuthAvailable = false;

        var defaultAuth = true;
        var defaultAuthAvailble = false;
        this.selectedProfile.environment.auths.map(a => {

            if (a.name == 'Default') {
                defaultAuthAvailble = true;
                if (a.invalid)
                    defaultAuth = false;
            }


            if (a.name.startsWith('ROLE_')) {
                rbacAuthAvailable = true;
                if (a.invalid)
                    rbacAuth = false
            }

            if ((a.name == 'UserA') || (a.name == 'UserB') || (a.name == 'UserC')) {
                abacAuthAvailable = true;
                if (a.invalid)
                    abacAuth = false
            }
        })

        this.categoryCount.map(element => {

            element['validAuth'] = defaultAuth;
            element['authAvailable'] = defaultAuthAvailble

            if (element.category.startsWith('Invalid') || element.category == 'Unsecured') {
                element['validAuth'] = '-';
                element['authAvailable'] = false
            }

            if (element.category == 'RBAC') {
                element['validAuth'] = rbacAuth
                element['authAvailable'] = rbacAuthAvailable
            }

            if (element.category.startsWith('ABAC')) {
                element['validAuth'] = abacAuth
                element['authAvailable'] = abacAuthAvailable
            }

        })
    }

}
